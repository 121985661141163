import { DeckData } from '../interfaces';

export const deckstringToValues = (deckstring: string) => {
    const tokens = deckstring.split(' ');

    const creaturesIndex = tokens.indexOf('creatures');
    const battlegearIndex = tokens.indexOf('battlegear');
    const mugicIndex = tokens.indexOf('mugic');
    const locationsIndex = tokens.indexOf('locations');
    const attacksIndex = tokens.indexOf('attacks');

    return {
        creatures: tokens.slice(creaturesIndex + 1, battlegearIndex).join('\n'),
        battlegear: tokens.slice(battlegearIndex + 1, mugicIndex).join('\n'),
        mugic: tokens.slice(mugicIndex + 1, locationsIndex).join('\n'),
        locations: tokens.slice(locationsIndex + 1, attacksIndex).join('\n'),
        attacks: tokens.slice(attacksIndex + 1).join('\n'),
    };
};

export const parseDeck = (filestring: string) => {
    return filestring
        .replace(/\n/g, ' ')
        .replace(/[^\w ]/g, '');
};

export const parseUntapDeck = (untapstring: string) => {
    const lines = untapstring.split('\n');

    const cards = lines.filter(line => line.length > 0 && !line.startsWith('\/\/'))
        .map(line => line.replace(/(\(dop\)|\(zoth\)|\(ss\)|\(btd\)|\(roto\)|\(tott\)|\(fu\)|\(au\)|\(fas\)|\(promo\))/g, ''))
        .reduce((cards, line) => {
            const tokens = line.split(' ');
            const count = parseInt(tokens[0]) || 0;
            const card = tokens.slice(1).join('').replace(/[^\w]/g, '').toLowerCase();
            for (let i = 0; i < count; i++) {
                cards.push(card);
            }
            return cards;
        }, []);

    const cardString = cards.join(' ');

    return `creatures ${cardString} battlegear ${cardString} mugic ${cardString} locations ${cardString} attacks ${cardString}`;
};

export const clean = (value: string) => {
    return value.replace(/[^\w]/g, '').toLowerCase();
};

export const getDeckString = (deck: DeckData) => {
    return Object.keys(deck)
        .map(key => `${key} ${deck[key].map(card => clean(card.name + card.version) + `${card.alt > 0 ? card.alt : ''}`).join(' ')}`)
        .join(' ');
};
