import * as React from 'react';

import {
    BurstElementData,
    ChoiceData,
    GameData,
    GamePosition,
    MouseOverData,
    OnSelect,
    ViewDeck,
} from '../interfaces';
import { ActiveLocation } from './ActiveLocation';
import { AttackHand } from './AttackHand';
import { Choices } from './Choices';
import { Targets } from './Targets';
import { Autopass } from './Autopass';

export interface UserAreaProps extends GameData {
    action: string;
    selected: GamePosition[];
    mouseOverTargets: GamePosition[];
    mouseOverBurstElement: BurstElementData;
    userAreaChoices: ChoiceData[];
    spectate: boolean;
    viewDeck: ViewDeck;
    onViewDeck: (viewDeck: null) => void;
    onSelectTarget: (type: string) => OnSelect;
    onSelectChoice: OnSelect;
    onSelectAttack: OnSelect;
    onSelectLocation: OnSelect;
    onMouseOverCard: (data: MouseOverData) => void;
    onPlayerSettingsUpdate: (settings: {[setting: string]: string}) => void;
}

export const UserArea = (props: UserAreaProps) => {
    const content = React.useMemo(() => {
        if (props.viewDeck) {
            let title = '';
            let choices: ChoiceData[] = [];
            switch (props.viewDeck) {
                case 'ownAttackDeck':
                    title = 'Your Attack Deck';
                    choices = props.ownAttackDeck.map<ChoiceData>((card) => ({ value: card, type: 'card' }));
                    break;
                case 'opponentAttackDeck':
                    title = 'Opponent\'s Attack Deck';
                    choices = props.opponentAttackDeck.map<ChoiceData>((card) => ({ value: card, type: 'card' }));
                    break;
                case 'ownLocationDeck':
                    title = 'Your Location Deck';
                    choices = props.ownLocationDeck.map<ChoiceData>((card) => ({ value: card, type: card.cardType || 'card' }));
                    break;
                case 'opponentLocationDeck':
                    title = 'Opponent\'s Location Deck';
                    choices = props.opponentLocationDeck.map<ChoiceData>((card) => ({ value: card, type: card.cardType || 'card' }));
                    break;
            }
            choices.unshift({ type: 'string', value: 'Done' });
            console.log(choices);
            return (
                <div>
                    <div className={'title'}>{`${title}:`}</div>
                    <Choices onSelect={() => props.onViewDeck(null)} onMouseOverCard={props.onMouseOverCard} data={choices} />
                </div>
            );
        }
        else if (props.mouseOverBurstElement
            && props.mouseOverBurstElement.effect
            && (props.mouseOverBurstElement.effect.targetsOwnDiscard
                || props.mouseOverBurstElement.effect.targetsOpponentsDiscard
                || props.mouseOverBurstElement.effect.targetTypes.includes('Player'))
            || props.action === 'target'
                && props.targeting
                && (props.targeting.targetsOptional
                    || props.targeting.targetsOwnDiscard
                    || props.targeting.targetsOpponentsDiscard
                    || props.targeting.targetTypes.includes('Player'))) {
            const type = props.targeting && props.targeting.targetTypes[props.selected.length];
            const data = { creatures: [], battlegear: [], mugic: [] };
            if (!props.targeting || props.targeting.targetsOwnDiscard) {
                data.creatures.push(...props.ownGeneralDiscard.creatures);
                data.battlegear.push(...props.ownGeneralDiscard.battlegear);
                data.mugic.push(...props.ownGeneralDiscard.mugic);
            }
            if (!props.targeting || props.targeting.targetsOpponentsDiscard) {
                data.creatures.push(...props.opponentsGeneralDiscard.creatures);
                data.battlegear.push(...props.opponentsGeneralDiscard.battlegear);
                data.mugic.push(...props.opponentsGeneralDiscard.mugic);
            }

            return (
                <div>
                    <div className={'title'}>Targets:</div>
                    <Targets
                        type={type}
                        selected={props.selected}
                        optional={props.targeting && props.targeting.targetsOptional}
                        p1={props.p1}
                        onSelectTarget={props.onSelectTarget}
                        mouseOverTargets={props.mouseOverTargets}
                        data={data} />
                </div>
            );
        } else if (props.action === 'choose' && props.userAreaChoices.length > 0) {
            return (
                <div>
                    <div className={'title'}>Choices:</div>
                    <Choices onSelect={props.onSelectChoice} onMouseOverCard={props.onMouseOverCard} data={props.userAreaChoices} />
                </div>
            );
        } else if (props.attacks) {
            return (
                <div>
                    <div className={'title'}>Attacks:</div>
                    <AttackHand
                        action={props.action}
                        onSelect={props.onSelectAttack}
                        onMouseOverCard={props.onMouseOverCard}
                        data={props.attacks} />
                </div>
            );
        }
    }, [props]);

    return (
        <div className={'user-area'}>
            {content}
            {props.activeLocation !== null &&
                <div>
                    <div className={'title'}>Active Location:</div>
                    <ActiveLocation
                        action={props.action}
                        onSelect={props.onSelectLocation}
                        onMouseOverCard={props.onMouseOverCard}
                        data={props.activeLocation}
                    />
                    {!props.spectate && <Autopass onPlayerSettingsUpdate={props.onPlayerSettingsUpdate} />}
                </div>
            }
        </div>
    );
};
