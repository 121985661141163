import * as React from 'react';
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom';

import { parseDeck, parseUntapDeck } from '../helpers/deck';
import { getSessionID, loggedIn } from '../helpers/local-storage';
import { DeckInfoData, DeckMetaDataData } from '../interfaces';
import { DeckEditor } from './DeckEditor';
import { DefaultDecksButton } from './DefaultDecksButton';
import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { LoginRedirect } from './LoginRedirect';
import { DecksList } from './DecksList';
import { BackButton } from './BackButton';

export interface DeckBuilderProps { socket: WebSocket }

export interface DeckBuilderState {
    message: string;
    deckMetaDatas: DeckMetaDataData[];
    deckInfo: DeckInfoData;
    saved: boolean;
    sharedDeckHash: string;
}

type Props = DeckBuilderProps & RouteComponentProps<object, object, { from?: string }>;

class DeckBuilderInner extends React.Component<Props, DeckBuilderState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: null,
            deckMetaDatas: [],
            deckInfo: null,
            saved: null,
            sharedDeckHash: null,
        };

        this.onSelect = this.onSelect.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onShare = this.onShare.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDuplicate = this.onDuplicate.bind(this);
        this.updateDeck = this.updateDeck.bind(this);
        this.saveDeck = this.saveDeck.bind(this);
        this.back = this.back.bind(this);

        this.props.socket.onmessage = this.onMessage.bind(this);
    }

    componentDidMount() {
        this.setState({ message: 'Fetching Decks' }, () => this.getDecks());
    }

    getDecks() {
        const sessionID = getSessionID();

        this.props.socket.send(`decks|${sessionID}`);
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        let newState: DeckBuilderState = { ...this.state, message: null, saved: null };
        if (data.message) {
            newState = { ...newState, message: data.message };
        }
        if (data.deckMetaDatas) {
            newState = { ...newState, deckMetaDatas: data.deckMetaDatas };
        }
        if (data.deckInfo) {
            newState = { ...newState, deckInfo: data.deckInfo };
        }
        if (data.deck) {
            newState = { ...newState, deckInfo: { ...newState.deckInfo, deck: data.deck } };
        }
        if (data.issues) {
            newState = { ...newState, deckInfo: { ...newState.deckInfo, issues: data.issues } };
        }
        if (data.saved) {
            newState = { ...newState, saved: data.saved };
        }
        if (data.sharedDeckHash) {
            newState = { ...newState, sharedDeckHash: data.sharedDeckHash };
        }
        this.setState(newState);
    }

    onSelect(deckID: number) {
        const sessionID = getSessionID();
        this.props.socket.send(`deck|${sessionID}|${deckID}`);
    }

    onMove(deckID: number, dragIndex: number, dropIndex: number) {
        const sessionID = getSessionID();
        this.props.socket.send(`movedeck|${sessionID}|${deckID}|${dragIndex}|${dropIndex}`);
    }

    onShare(deckID: number) {
        const sessionID = getSessionID();
        this.setState({ message: 'Generating Share Link' }, () => {
            this.props.socket.send(`sharedeck|${sessionID}|${deckID}`);
        });
    }

    onDelete(deckID: number) {
        const sessionID = getSessionID();
        this.props.socket.send(`deletedeck|${sessionID}|${deckID}`);
    }

    onDuplicate(deckID: number) {
        const sessionID = getSessionID();
        this.props.socket.send(`copydeck|${sessionID}|${deckID}`);
    }

    onAdd() {
        const sessionID = getSessionID();
        this.props.socket.send(`adddeck|${sessionID}`);
    }

    onImport(transform: (file: string) => string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            if (files.length !== 1) {
                return;
            }
            const file = files[0];

            const fileReader = new FileReader();
            fileReader.onload = () => {
                const sessionID = getSessionID();
                const name = file.name
                    .replace(/\.txt$/, '');
                const deckstring = transform(fileReader.result as string);
                this.props.socket.send(`importdeck|${sessionID}|${name}|${deckstring}`);
            };
            fileReader.readAsText(file, 'utf-8');
        };
    }

    updateDeck(deckstring: string) {
        const sessionID = getSessionID();
        this.props.socket.send(`deckstring|${sessionID}|${deckstring}`);
    }

    saveDeck(deckID: number, name: string, deckstring: string, format: string) {
        if (name.length === 0) {
            this.setState({ deckInfo: { ...this.state.deckInfo, issues: ['Name must not be empty'] } });
        } else {
            const sessionID = getSessionID();

            this.props.socket.send(`savedeck|${sessionID}|${deckID}|${name}|${deckstring}|${format}`);
        }
    }

    back() {
        this.setState({ deckInfo: null }, () => this.getDecks());
    }

    render() {
        if (!loggedIn()) {
            return <LoginRedirect />;
        }

        if (this.state.sharedDeckHash !== null) {
            return <Redirect to={`/deck/${this.state.sharedDeckHash}`} />;
        }

        if (this.state.message !== null) {
            return <FullScreenMessage message={this.state.message} />;
        }

        if (this.state.deckInfo !== null && this.state.deckInfo.deck) {
            return <DeckEditor
                socket={this.props.socket}
                deckInfo={this.state.deckInfo}
                saved={this.state.saved}
                updateDeck={this.updateDeck}
                saveDeck={this.saveDeck}
                back={this.back} />;
        }

        return (
            <div className={'deck-builder'}>
                <div className={'title-row'}>
                    <div className={'left'}>
                        {this.props.location.state?.from ?
                            <BackButton path={this.props.location.state?.from} /> :
                            <HomeButton />
                        }
                        <div className={'title'}>Deck Builder</div>
                    </div>
                    <div className={'right'}>
                        <DefaultDecksButton onClick={() => this.forceUpdate()} />
                    </div>
                </div>
                <div className={'deck-creation-buttons'}>
                    <div className={'deck-creation-button add-deck'}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.onAdd()}>
                        <div>{'New Deck'}</div>
                    </div>
                    <label className={'deck-creation-button import-deck-label'}>
                        Import Deck
                        <input className={'import-deck-input'} type={'file'} accept={'text/plain'} onChange={this.onImport(parseDeck)} />
                    </label>
                    <label className={'deck-creation-button import-deck-label'}>
                        Import untap.in Deck
                        <input className={'import-deck-input'} type={'file'} accept={'text/plain'} onChange={this.onImport(parseUntapDeck)} />
                    </label>
                </div>
                <DecksList
                    showIcons
                    showInvalid
                    decks={this.state.deckMetaDatas}
                    onSelect={this.onSelect}
                    onMove={this.onMove}
                    onShare={this.onShare}
                    onDuplicate={this.onDuplicate}
                    onDelete={this.onDelete}
                    build
                />
            </div>
        );
    }
}

export const DeckBuilder = withRouter(DeckBuilderInner);
