import * as React from 'react';
import { Helmet } from 'react-helmet';

import { getShowDefaultDecks } from '../../helpers/local-storage';
import { DeckMetaDataData } from '../../interfaces';
import { DecksListItem, DecksListItemProps } from '../DecksListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MobileViewPort from '../MobileViewPort';

export interface DecksListProps extends Omit<DecksListItemProps, 'deckMetaData' | 'index'> {
    decks: DeckMetaDataData[];
    format: string;
    showInvalid: boolean;
    build: boolean;
}

export const DecksList = (props: DecksListProps) => {
    const { decks, format, showInvalid, build, ...rest } = props;

    return (
        <div className={`decks-list${(build === false && format === null) ? ' custom' : ''}`}>
            <MobileViewPort />
            <DndProvider backend={HTML5Backend}>
                {getShowDefaultDecks() && decks.filter(deckMetaData => deckMetaData.default !== 0)
                    .filter(deckMetaData => format === null || deckMetaData.format === format)
                    .map((deckMetaData, index) => (
                        <DecksListItem
                            key={deckMetaData.id}
                            index={index}
                            deckMetaData={deckMetaData}
                            {...rest}
                            onMove={null}
                        />
                    ))
                }
                {decks.filter(deckMetaData => deckMetaData.default === 0)
                    .filter(deckMetaData => format === null || deckMetaData.format === format)
                    .filter(deckMetaData => showInvalid || deckMetaData.valid === 1)
                    .map((deckMetaData, index) => (
                        <DecksListItem
                            key={deckMetaData.id}
                            index={index}
                            deckMetaData={deckMetaData}
                            {...rest}
                        />
                    ))
                }
            </DndProvider>
        </div>
    );
};

DecksList.defaultProps = {
    format: null,
    build: false,
    showInvalid: false,
    showIcons: false,
    onMove: null,
    onShare: null,
    onDuplicate: null,
    onDelete: null,
};
