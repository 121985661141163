import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DeckMetaDataData } from '../interfaces';
import { CustomSettings, CustomSettingsProps } from './CustomSettings';
import { DefaultDecksButton } from './DefaultDecksButton';
import { HomeButton } from './HomeButton';
import { DecksList } from './DecksList';
import withLocation, { LocationProps } from './withLocation';

export interface DeckPickerProps extends Omit<CustomSettingsProps, 'create'> {
    decks: DeckMetaDataData[];
    format: string;
    onSelect: (deckID: number) => void;
}

class DeckPickerInner extends React.Component<DeckPickerProps & LocationProps> {
    static defaultProps = {
        format: null,
        gameSettings: {},
        gameKey: null
    }

    constructor(props: DeckPickerProps & LocationProps) {
        super(props);
    }

    render() {
        return (
            <div className={'deck-picker'}>
                <div className={'title-row'}>
                    <div className={'left'}>
                        <HomeButton />
                        <div className={'title'}>Pick a deck</div>
                    </div>
                    <div className={'right'}>
                        <DefaultDecksButton onClick={() => this.forceUpdate()} />
                    </div>
                </div>
                <Link to={{
                    pathname: '/deckbuilder',
                    state: { from: this.props.location.pathname }
                }} className={'deck-builder-button'}>
                    <div>Deck Builder</div>
                </Link>
                <DecksList
                    decks={this.props.decks}
                    format={this.props.format}
                    onSelect={this.props.onSelect} />
                {(this.props.gameKey && Object.keys(this.props.gameSettings).length > 0) && (
                    <CustomSettings {...this.props} />
                )}
            </div>
        );
    }
}

export const DeckPicker = withLocation(DeckPickerInner);
