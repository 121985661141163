import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

export type LocationProps = {
  location: Location;
};

function withLocation<T extends LocationProps>(
  WrappedComponent: React.ComponentType<T>
) {
    const ComponentWithLocation = (props: Omit<T, keyof LocationProps>) => {
        const location = useLocation();
        return <WrappedComponent {...(props as T)} location={location} />;
    };

    // Set displayName
    ComponentWithLocation.displayName = `WithLocation(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return ComponentWithLocation;
}

export default withLocation;
