import * as React from 'react';

export interface TooltipProps {
    children: React.ReactNode;
    text: string;
}

export const Tooltip = ({ children, text }: TooltipProps) => {
    const [show, setShow] = React.useState(false);

    return (
        <div
            className={'tooltip-container'}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            {children}
            {show && <div className={'tooltip'}>{text}</div>}
        </div>
    );
};

export default Tooltip;
