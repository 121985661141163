import * as React from 'react';
import MobileViewPort from './MobileViewPort';

export interface FullScreenMessageProps {
    message: string;
    children?: React.ReactNode;
 }

export const FullScreenMessage = (props: FullScreenMessageProps) => {
    return (
        <div className={'full-screen-message'}>
            {props.message && <div>{props.message}</div>}
            {props.children}
        </div>
    );
};
