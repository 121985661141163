import * as React from 'react';
import { DeckData } from '../interfaces';

interface PrintDeckProps {
    name: string;
    deck: DeckData;
}

const PrintDeck = ({ name, deck } : PrintDeckProps) => {
    return (
        <div className='print-deck'>
            <div className='print-cards'>
                {deck.creatures.map((creature, index) => (
                    <div className={'print-image'} key={index}>
                        <img src={creature.imageURL} />
                    </div>
                ))}
                {deck.battlegear.map((creature, index) => (
                    <div className={'print-image'} key={index}>
                        <img src={creature.imageURL} />
                    </div>
                ))}
                {deck.mugic.map((creature, index) => (
                    <div className={'print-image'} key={index}>
                        <img src={creature.imageURL} />
                    </div>
                ))}
                {deck.locations.map((creature, index) => (
                    <div className={'print-image'} key={index}>
                        <div className={'print-image-location'}>
                            <img src={creature.imageURL} />
                        </div>
                    </div>
                ))}
                {deck.attacks.map((creature, index) => (
                    <div className={'print-image'} key={index}>
                        <img src={creature.imageURL} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PrintDeck;
