import * as React from 'react';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { DeckData } from '../interfaces';
import { DeckPreview } from './DeckPreview';
import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { getSessionID } from '../helpers/local-storage';
import PrintDeck from './PrintDeck';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface SharedDeckProps {
    socket: WebSocket;
}

export interface SharedDeckState {
    message: string;
    name: string;
    deck: DeckData;
    deckstring: string;
}

export class SharedDeck extends React.Component<SharedDeckProps & RouteComponentProps, SharedDeckState> {
    constructor(props: SharedDeckProps & RouteComponentProps) {
        super(props);

        this.state = {
            message: 'Connecting to Server',
            name: null,
            deck: null,
            deckstring: null,
        };

        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
    }

    componentDidMount() {
        this.props.socket.onopen = this.onOpen;
        this.props.socket.onmessage = this.onMessage.bind(this);

        if (this.props.socket.readyState === WebSocket.OPEN) {
            this.onOpen();
        }
    }

    onOpen() {
        const hash = this.props.match.params['deck'];
        this.setState({ message: 'Fetching Deck' }, () => {
            const sessionID = getSessionID();
            this.props.socket.send(`getshareddeck|${hash}|${sessionID ?? ''}`);
        });
    }

    onMessage(event: MessageEvent) {
        const data = JSON.parse(event.data);
        console.log(data);
        this.setState({
            message: data.error || null,
            name: data.name || null,
            deck: data.deck || null,
            deckstring: data.deckstring || null,
        });
    }

    render() {
        if (this.state.message !== null) {
            return <FullScreenMessage message={this.state.message} />;
        }
        return (
            <Switch>
                <Route exact path={`${this.props.match.url}`} render={() => (
                    <div className={'shared-deck'}>
                        <div className={'shared-deck-info-row'}>
                            <HomeButton />
                            <div className={'deck-name'}>{this.state.name}</div>
                            <div className={'buttons'}>
                                <Link to={`/deck/${this.props.match.params['deck']}/print`} className={'print-button'} title={'Print Deck'}>
                                    <FontAwesomeIcon icon={faPrint} />
                                </Link>
                                <a className={'download-button'} href={`data:text/plain;charset=utf-8,${this.state.deckstring}`} title={'Download Deck'}
                                    download={`${this.state.name}.txt`}
                                    onClick={event => event.stopPropagation()}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </a>
                            </div>
                        </div>
                        <DeckPreview deck={this.state.deck} />
                    </div>
                )} />
                <Route exact path={`${this.props.match.url}/print`} render={() => (
                    <PrintDeck {...this.state} />
                )} />
            </Switch>
        );
    }
}
