import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Tooltip from './Tooltip';

export interface AutopassProps {
    onPlayerSettingsUpdate: (settings: {[setting: string]: string}) => void;
}

export const Autopass = (props: AutopassProps) => {
    const [autopassUntilAttack, setAutopassUntilAttack] = React.useState(false);
    const [autopassUntilBurst, setAutopassUntilBurst] = React.useState(false);
    const [autopassEverything, setAutopassEverything] = React.useState(false);

    const updateAutopassUntilAttack = () => {
        props.onPlayerSettingsUpdate({ 'autopassUntilAttack': `${!autopassUntilAttack}` });
        setAutopassUntilAttack(!autopassUntilAttack);
    };

    const updateAutopassUntilBurst = () => {
        props.onPlayerSettingsUpdate({ 'autopassUntilBurst': `${!autopassUntilBurst}` });
        setAutopassUntilBurst(!autopassUntilBurst);
    };

    const updateAutopassEverything = () => {
        props.onPlayerSettingsUpdate({ 'autopassEverything': `${!autopassEverything}` });
        setAutopassEverything(!autopassEverything);
    };

    const updateAutopassOff = () => {
        props.onPlayerSettingsUpdate({
            'autopassUntilAttack': 'false',
            'autopassUntilBurst': 'false',
            'autopassEverything': 'false'
        });

        setAutopassUntilAttack(false);
        setAutopassUntilBurst(false);
        setAutopassEverything(false);
    };

    const off = !autopassUntilAttack && !autopassUntilBurst && !autopassEverything;

    return (
        <>
            <div className='autopass'>
                <div className='autopass-title'>
                    <Tooltip text={'Manual Autopass will skip when you could otherwise have priority.\nYou will still get priority at the start of turns and combat.\n• Pass bursts without an attack\n• Pass bursts without an Mugic or Activated Ability\n• Pass every priority'}>
                        <div className={`option${off ? ' selected' : ''}`} onClick={updateAutopassOff}>Autopass Off <FontAwesomeIcon icon={faQuestionCircle} /></div>
                    </Tooltip>
                </div>
            </div>
            <div className='autopass'>
                <div className={`option${autopassUntilAttack ? ' selected' : ''}`} onClick={updateAutopassUntilAttack}>Attack</div>
                <div className={`option${autopassUntilBurst ? ' selected' : ''}`} onClick={updateAutopassUntilBurst}>Burst</div>
                <div className={`option${autopassEverything ? ' selected' : ''}`} onClick={updateAutopassEverything}>Everything</div>
            </div>
        </>
    );
};
