import * as React from 'react';

import { FullScreenMessage } from './FullScreenMessage';
import { HomeButton } from './HomeButton';
import { isRotationDay } from '../helpers/rotation';

export interface BanlistsProps { socket: WebSocket }

interface FormatList {
    [format: string]: {
        banlist: string[];
        uniquelist: string[];
        loyalList: string[];
        ununiqueList: string[];
        unloyalList: string[];
        unlegendaryList: string[];
    }
}

export const Banlists = (props: BanlistsProps) => {
    const [format, setFormat] = React.useState('standard');
    const [message, setMessage] = React.useState('Fetching Banlists');
    const [lists, setLists] = React.useState<FormatList>({});

    React.useEffect(() => {
        props.socket.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            setLists((lists) => ({ ...lists, ...data }));
            setMessage(null);
        };
    }, []);

    React.useEffect(() => {
        if (!lists.hasOwnProperty(format)) {
            props.socket.send(`banlists|${format}`);
        }
    }, [format]);

    const formats = React.useMemo(() => {
        const formats = {
            standard: 'Standard',
            experimental: 'Standard (Whacky Weekend)',
            unrestricted: 'Unrestricted',
            advanced_apprentice: 'Advanced Apprentice',
            pauper: 'Pauper',
        };
        if (isRotationDay() && lists.hasOwnProperty('experimental')) {
            delete formats['standard'];
            setFormat('experimental');
        } else {
            delete formats['experimental'];
        }
        return formats;
    }, [lists]);

    if (message) {
        return <FullScreenMessage message={message} />;
    }

    return (
        <div className={'banlists'}>
            <div className={'title-row'}>
                <HomeButton />
                <div className={'title'}>Banlists</div>
            </div>
            <div className='tabs'>
                {Object.entries(formats).map(([f, name]) => (
                    <div key={f}
                        className={`tab ${f === format ? 'active' : 'inactive'}`}
                        onClick={() => setFormat(f)}
                    >
                        {name}
                    </div>
                ))}
            </div>
            <div className={'columns'}>
                <div className={'column'}>
                    <div className={'title'}>Banned Cards</div>
                    <div className={'line'} />
                    <div className={'content'}>
                        {lists[format] && lists[format].banlist.map((card, index) => (
                            <div key={index}>{card}</div>
                        ))}
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'title'}>Unique Cards</div>
                    <div className={'line'} />
                    <div className={'content'}>
                        {lists[format] && lists[format].uniquelist.map((card, index) => (
                            <div key={index}>{card}</div>
                        ))}
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'title'}>Ununique Cards</div>
                    <div className={'line'} />
                    <div className={'content'}>
                        {lists[format] && lists[format].ununiqueList.map((card, index) => (
                            <div key={index}>{card}</div>
                        ))}
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'title'}>Loyal Cards</div>
                    <div className={'line'} />
                    <div className={'content'}>
                        {lists[format] && lists[format].loyalList.map((card, index) => (
                            <div key={index}>{card}</div>
                        ))}
                    </div>
                </div>
                <div className={'column'}>
                    <div className={'title'}>Unloyal Cards</div>
                    <div className={'line'} />
                    <div className={'content'}>
                        {lists[format] && lists[format].unloyalList.map((card, index) => (
                            <div key={index}>{card}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
