import * as React from 'react';
import { Helmet } from 'react-helmet';

const MobileViewPort = () => {
    return (
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" key="viewport" />
        </Helmet>
    );
};

export default MobileViewPort;
