import './style/index.less';

import * as React from 'react';
import * as ReactDeviceDetect from 'react-device-detect';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import { FullScreenMessage } from './components/FullScreenMessage';
import { SocketWrapper } from './components/SocketWrapper';

export const NODE_ENV = process.env.NODE_ENV || 'development';
export const HOST = process.env.HOST || 'localhost';
export const PORT = process.env.PORT || 8080;
export const DISCORD_CLIENT_ID = process.env.DISCORD_CLIENT_ID || '727281582028619988';
export const DISCORD_SCOPE = process.env.DISCORD_SCOPE || 'identify email';
export const DISCORD_REDIRECT_URI = process.env.DISCORD_REDIRECT_URI || 'http://localhost:8000/login';

if (NODE_ENV === 'development') {
    console.log('config', { NODE_ENV, HOST, PORT, DISCORD_CLIENT_ID, DISCORD_SCOPE, DISCORD_REDIRECT_URI });
}

const AppWrapper = () => {
    const [initialScale, setInitialScale] = React.useState('');

    React.useEffect(() => {
        // Capture the device's current scale on app load
        if (window.visualViewport) {
            setInitialScale(window.visualViewport.scale.toString());
        } else {
            setInitialScale('1.0');
        }
    }, []);

    if (initialScale === '') {
        /* this is used to grab the initial scaling */
        return (
            <div className={'home'} />
        );
    }

    return (<>
        <Helmet>
            {<meta name="viewport" content={`width=device-width, initial-scale=${initialScale}`} key="viewport"/>}
        </Helmet>
        <SocketWrapper />
    </>);
};

const content = () => {
    const browserName = ReactDeviceDetect.browserName;
    const browserVersion = parseInt(ReactDeviceDetect.browserVersion);
    if (browserName === 'Edge' && browserVersion && browserVersion < 79
        || browserName === 'Internet Explorer') {
        return <FullScreenMessage message={'Legacy Edge and Internet Explorer are not supported'} />;
    }
    return <AppWrapper />;
};

ReactDOM.render(
    content(),
    document.getElementById('app')
);
