import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const BackButton = ({ path }: {path: string}) => (
    <Link className={'home-button'} to={path}>
        <FontAwesomeIcon icon={faArrowLeft} />
    </Link>
);
